import api from './api'

export default {
    vincular(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/modulos/vincular', body, config)
    },
    desvincular(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/modulos/desvincular', body, config)
    },   
    buscarRelacoes(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/modulos/selecionar-relacoes', body, config)
    },  
    buscar(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/modulos/selecionar', body, config)
    },  
    buscarAPIs(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/modulos/apis/selecionar', body, config)
    },  
    salvarAPIs(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/modulos/apis/salvar', body, config)
    },  
}