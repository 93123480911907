<template>
  <div>
    <ListaAPI />
  </div>
</template>

<script>
import ListaAPI from '@/components/APIs/ListaAPI'

export default {
  components: {ListaAPI},
  data(){
    return{
      
    }
  },
  mounted(){
    
  },
  methods: {
    
  }
}
</script>

<style>

</style>