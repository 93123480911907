<template>
  <v-container>
      <div class="header">
        <span>
          <h3>Status das APIs</h3>
          <p>Listagem de APIs - Clique no botão ao lado para atualizar os status.</p>
        </span>
        <div class="grow"/>
          <v-btn  small class="ml-2 p-3" style="margin: 0 0 0 0" @click="atualizarTodosStatus()">
            <v-icon style="margin: 0 0 0 0" color="green" title="Atualizar Todas">
              mdi-reload
            </v-icon>
          </v-btn>
      </div>

    <div style="display:flex;">
          <div class="containerFiltros">
            <div style="width:200px;">
              Api Central E-Spot: 
              <span v-if="statusApiCentralEspot" style="font-weight:bold;font-size:20px;color:green;">Ativado</span>
              <span v-else  style="font-weight:bold;font-size:20px;color:red;">Desativado</span>
            </div>
          </div>

          <div class="containerFiltros">
            <div style="width:200px;">
              Api Central Web Check In: 
              <span v-if="statusApiCentralCheckIn" style="font-weight:bold;font-size:20px;color:green;">Ativado</span>
              <span v-else  style="font-weight:bold;font-size:20px;color:red;">Desativado</span>
            </div>
          </div>

          <div class="containerFiltros">
            <div style="width:200px;">
              Api Central Web Check Out: 
              <span v-if="statusApiCentralCheckOut" style="font-weight:bold;font-size:20px;color:green;">Ativado</span>
              <span v-else  style="font-weight:bold;font-size:20px;color:red;">Desativado</span>
            </div>
          </div>

    </div>
    

    <div class="containerFiltros">
    
      <div style="width:80vw;">
             <v-container class="">
                <v-row>
                  <!-- <v-col cols="12" md="3" style="">
                      <v-text-field hide-details type="" label="Digite um nome" style="margin-bottom:0px;padding-bottom:0px!important;" v-model="inputNome" @keyup="filtroPesquisaNome" outlined ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" md="3">
                      <v-select return-object hide-details  :items="sistemas" item-text="nome" item-value="value" label="Filtrar por sistema" v-model="sistemaSelecionado" outlined >
                      </v-select>
                  </v-col>
                  <!-- <v-col cols="12" md="3">
                      <v-select hide-details  :items="status" item-text="nome" item-value="value" label="Status" v-model="statusSelecionado" return-object outlined>
                      </v-select>
                  </v-col> -->
                  <v-col cols="12" md="3">
                      <v-select hide-details  :items="tipointegracao" label="Tipo Integração" item-text="nome" item-value="value" v-model="tipointegracaoSelecionado" return-object outlined>
                      </v-select>
                  </v-col>
                </v-row>
              </v-container>
        
      </div>
      <div class="containerFiltroSistema">
          <!-- <button type="button" class="btnAddFiltro" @click="addFiltroSistema">Filtrar</button> -->
          <button type="button" class="btnAddFiltro" @click="buscarAPIs">Filtrar</button>
          <button type="button" class="btnLimarFiltro" @click="limparFiltroSistema">Limpar filtro</button>
      </div>
    </div>
    <section class="wrapper-list" v-if="loading">
      <!-- lista de apis -->
      <v-data-table 
        :items="apis"
        :headers="headers"
        :items-per-page="50"
        >

        <template v-slot:[`item.status`]="{ item }">
          <div style="display:flex; align-items: center;">
              <div :class="getClass(item)">{{item.status}}</div>
          </div>
        </template>

        <template v-slot:[`item.url`]="{ item }">
          <v-text-field v-model="editedItem.url" :hide-details="true" dense single-line v-if="item.idcliente_sistema === editedItem.idcliente_sistema"></v-text-field>
          <span v-else>{{item.url}}</span>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div style="display:flex; align-items: center;">
            <div v-if="item.id === editedItem.id">
              <v-icon color="red" class="mr-3" @click="close">
                mdi-window-close
              </v-icon>
              <v-icon color="green"  @click="salvarAPI">
                mdi-content-save
              </v-icon>
            </div>
            <div v-else>
              <v-icon color="green" class="mr-3" @click="editItem(item)"  title="Editar">
                mdi-pencil
              </v-icon>
            </div>

            <v-btn  small class="ml-2" style="margin: 0 0 0 0" @click="atualizarStatus(item)">
              <v-icon style="margin: 0 0 0 0" color="green" title="Atualizar">
                mdi-reload
              </v-icon>              
            </v-btn>
          </div>
        </template>

      </v-data-table>
    </section>
    <ListSkeleton  v-if="!loading"/>

  </v-container>
</template>

<script>
import {alertar} from '@/services/notificacao.service.js';
import ClienteServico from '@/services/cliente.service.js';
import ListSkeleton from '@/components/Loading/ListSkeleton.vue';
import sistemasCliente from '@/services/sistemasCliente.service.js';
import SistemaServico from '@/services/sistema.service.js';
import AtividadeServico from '@/services/atividade.service.js'
import dayjs from 'dayjs'
import ModuloServico from '@/services/modulos.service.js'
import axios from 'axios';
import api from '../../services/api'

export default {
  components: {ListSkeleton},
  data(){
    return{
      movimento:{idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null},
      inputNome: '',
      statusApiCentralEspot:'',
      statusApiCentralCheckIn:'',
      statusApiCentralCheckOut:'',
      SistemasDoCliente: [],
      sistemaSelecionado: {nome:'Ficha Digital',valor:1},
      sistemas: [
        {nome:'Ficha Digital',valor:1},
        {nome:'Cardápio Digital',valor:2},
        {nome:'FD Guest',valor:3},
        {nome:'E-Spot',valor:4},
        {nome:'Check-out Express',valor:30},
        {nome:'Check-in Pay',valor:31},
      ],
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Cliente', value: 'cliente' },
        { text: 'Sistema', value: 'sistema' },
        //{ text: 'Módulo', value: 'modulo' },
        { text: 'URL', value: 'url'},
        { text: 'Status', value: 'status'},
        { text: 'Ações', value: 'action' },
        { text: 'Resposta', value: 'response'},
      ],
      items: [],
      filtroPorSistema: [],
      itemsParaFiltros: [],
      loading: false,
      status:[
        {nome:'Ativo',valor:true},
        {nome:'Desativado',valor:false}
      ],
      statusSelecionado:{nome:'Ativo',valor:true},
      tipointegracao:[
        {nome:'Totvs Oracle',valor:1},
        {nome:'Totvs Sql Server',valor:2},
        {nome:'Hits',valor:3},
        {nome:'ERBON',valor:4},
        {nome:'ADMH',valor:5},
        {nome:'NEW HOTEL',valor:6},
      ],
      tipointegracaoSelecionado:{nome:'Totvs Oracle',valor:1},
      movimentos:[],
      clienteselecionado:{},
      apis: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    }
  },
  methods:{
    async salvarAPI() {
      if (this.editedIndex > -1) {
        Object.assign(this.apis[this.editedIndex], this.editedItem)
      }
      let params = {
        idcliente_sistema: this.editedItem.idcliente_sistema,
        url: this.editedItem.url
      }
      await ModuloServico.salvarAPIs(params)
        .then(() => {
          console.error(`salvarAPI OK`)
          this.close();
        })
        .catch((e) => {
          //console.error(`buscarAPIs Erro`)
          console.error(e)
        })
    },
    close () {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.apis.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    getClass(item) {
      let result = `status-sem-url`
      if (item.status == 'ativo') { result = `status-ativo` }
      if (item.status == 'inativo') { result = `status-inativo` }
      if (item.status == 'esperando') { result = `status-esperando` }
      if (item.status == 'urlinvalida') { result = `status-sem-url` }
      return result
    },
    atualizarTodosStatus() {
        this.apis.forEach((api)=>{
          this.atualizarStatus(api);
        })
    },
    atualizarStatus(item) {
      if (item.url?.trim()) {
        item.status = 'esperando'
        axios.get(item.url)
        .then((res)=>{
          item.status = 'ativo'
          item.response = res.data
        })
        .catch((e)=>{
          item.status = 'inativo'
          item.response = JSON.stringify(e)
        })
      } else {
        item.status = 'urlinvalida'
        item.response = 'URL inválida.'
      }
    },
    async registrarMovimento(tipo){

        if(this.movimento.descricao){

            this.movimento.idusuario = localStorage.getItem('idusuario');
            this.movimento.tipomovimento = tipo;
            this.movimento.idcliente = this.clienteselecionado.id
            this.movimento.datahoramovimento = dayjs().format()

            await AtividadeServico.registrarMovimentoPorCliente(this.movimento).then(async () => { 
                this.movimento = {idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null}
                this.buscarMovimentosPorCliente({id:this.clienteselecionado.id})
            }).catch(error=>{
                alert('Não foi Possível Registrar!6')
                console.log(error)
                this.exibirModalCriarAtividade=false
            })
        }else{
            alert("Titulo Obrigatório!")
        }

    },
    async buscarMovimentosPorCliente (cliente){


            await AtividadeServico.buscarMovimentoPorCliente({idcliente:cliente.id}).then(async (resp) => { 

                let movimentos = resp.data
                movimentos = movimentos.sort(function (a, b) {
                    if (a.datahoramovimento > b.datahoramovimento) {
                        return -1;
                    }
                    if (a.datahoramovimento < b.datahoramovimento) {
                        return 1;
                    }
                    return 0;
                })
                this.movimentos = movimentos

            }).catch(error=>{
                alert('Não foi Possível Registrar!4')
                console.log(error)
            })

    },
    async requisitarClientes(){

      // console.log('tipointegracao:this.tipointegracao ',this.tipointegracaoSelecionado)
      // console.log('statusSelecionado:this.statusSelecionado ',this.statusSelecionado)
      // console.log('sistemaSelecionado:this.sistemaSelecionado ',this.sistemaSelecionado)
      let objetofiltro = {tipointegracao:this.tipointegracaoSelecionado.valor,ativo:this.statusSelecionado.valor,sistema:this.sistemaSelecionado.valor,nome:this.inputNome}
      
      this.items = await ClienteServico.buscar(objetofiltro).then(res=> res.data)
      this.itemsParaFiltros = await ClienteServico.buscar(objetofiltro).then(res=> res.data)
      for(let i = 0 ; i < this.items.length; i++){
        if(this.items[i].ativo == true){
          this.items[i].ativo = "Sim"
          this.itemsParaFiltros[i].ativo = "Sim"
        }else{
          this.items[i].ativo = "Não"
          this.itemsParaFiltros[i].ativo = "Não"
        }
      }
    },

    async requisitarSistemasDoCliente(){
      const buscaSistemas = await sistemasCliente.buscar({id: this.id}).then(res=> res)
      this.SistemasDoCliente = buscaSistemas.data;
     
    },

    addFiltroSistema() {
      if(this.sistemaSelecionado.length === 0) {
        return alertar('warning', '', 'Selecione um sistema')
      }
      const filter = this.SistemasDoCliente.filter((cliente) => cliente.nome_sistema.toLowerCase().includes(this.sistemaSelecionado.toLowerCase()));
      let filtersObj = [];
      filter.forEach((cliente) => {
        const obj = {
        id: cliente.id_cliente,
        nome: cliente.nome_cliente,
        contato1: cliente.contato1,
        contato2: cliente.contato2,
        telefonecontato1: cliente.telefonecontato1,
        telefonecontato2: cliente.telefonecontato2,
      }
      filtersObj = [...filtersObj, obj];
      })
      this.filtroPorSistema = filtersObj;
      this.items = filtersObj;
    },

    limparFiltroSistema() {
      this.items = this.itemsParaFiltros;
      this.inputNome = "";
      this.sistemaSelecionado = {nome:'Todos',valor:0}
      this.statusSelecionado = {nome:'Ativo',valor:true}
      this.tipointegracaoSelecionado ={nome:'Todos',valor:0}
    },


    async requisitarSistemas(){
      const buscaSistemas = await SistemaServico.buscar().then(res=> res.data)
      console.log(buscaSistemas)
      buscaSistemas.forEach((sistema) => {
        this.sistemas = [...this.sistemas, sistema.nome];
      });
      return buscaSistemas;
    },


    filtroPesquisaNome() {
      if (this.filtroPorSistema.length > 0) {
        const filter = this.filtroPorSistema.filter((cliente) => cliente.nome.toLowerCase().includes(this.inputNome.toLowerCase()));
        this.items = filter;
      } else {
        const filter = this.itemsParaFiltros.filter((cliente) => cliente.nome.toLowerCase().includes(this.inputNome.toLowerCase()));
        this.items = filter;
      }
     
    },

    checarAPIs(){

        api().post('/monitorarApi/checarStatusApis',{api:'espotcentral'}).then(resp =>{
            console.log('aa checarAPIs resp ', resp)
            this.statusApiCentralEspot = true
        }).catch(error =>{
            this.statusApiCentralEspot = false
            console.log('aa error ',error)
        })

        api().post('/monitorarApi/checarStatusApis',{api:'webcheckin'}).then(resp =>{
            console.log('aa checarAPIs resp ', resp)
            this.statusApiCentralCheckIn = true
        }).catch(error =>{
            this.statusApiCentralCheckIn = false
            console.log('aa error ',error)
        })

        api().post('/monitorarApi/checarStatusApis',{api:'webcheckout'}).then(resp =>{
            console.log('aa checarAPIs resp ', resp)
            this.statusApiCentralCheckOut = true
        }).catch(error =>{
            this.statusApiCentralCheckOut = false
            console.log('aa error ',error)
        })
        

      },

    async buscarAPIs(){
      //console.error(`buscarAPIs`)
      let params = {
        idsistema: this.sistemaSelecionado.valor,
        idtipointegracao: this.tipointegracaoSelecionado.valor
      }
      await ModuloServico.buscarAPIs(params)
        .then((res) => {
          this.apis = res.data
          //console.error(`buscarAPIs OK`)
        })
        .catch((e) => {
          //console.error(`buscarAPIs Erro`)
          console.error(e)
        })
    },    
  },
  async mounted(){
    await this.requisitarClientes()
    await this.requisitarSistemasDoCliente()
    await this.requisitarSistemas()
    await this.buscarAPIs()
    this.loading = true
    this.checarAPIs()
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }

.grow{
  flex: 1;
}

.containerFiltros {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 80vw;
  margin: auto;
  margin-bottom: 20px;
}

.inputPesquisa {
  background-color: white;
  margin: 0 auto;
  height: 50px;
  padding: 15px;
  border-radius: 8px;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.containerFiltroSistema {
  width: 80vw;
  display: flex;
  justify-content: flex-end;
}

.select {
  width: 100%;
  margin-top: 20px;
}

.btnAddFiltro {
  background-color: #1377f2;
  border-radius: 5px;
  width: 200px;
  height: 30px;
  cursor: pointer;
  color: white;
}

.btnLimarFiltro {
  background-color: #fb8c00;
  border-radius: 5px;
  width: 150px;
  height: 30px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}

.status-sem-url {
  background-color: #fb8c00;
  border-radius: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: white;
}

.status-esperando{
  background-color: white;
  border-radius: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: #fb8c00;
}

.status-inativo {
  background-color: #ff2233;
  border-radius: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: white;
}

.status-ativo {
  background-color: #22ff33;
  border-radius: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: rgb(27, 25, 25);
}

</style>